import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        user: null
    }),
    mutations: {
        setUserData(state, userData) {
            state.user = userData;
            localStorage.setItem('user', JSON.stringify(userData));
        },
        setUserProfileData(state, userProfileData) {
            state.userProfile = userProfileData;
            localStorage.setItem('userProfile', JSON.stringify(userProfileData));
        },
        clearUserData(state) {
            state.user = null;
            localStorage.removeItem('user');
            axios.defaults.headers.common.Authorization = `Bearer`;
        },
        clearUserProfileData(state) {
            state.userProfile = null;
            localStorage.removeItem('userProfile');
            axios.defaults.headers.common.Authorization = `Bearer`;
        }
    },

    actions: {
        // eslint-disable-next-line no-unused-vars
        async makeOrder({commit}, data) {
            let userData = JSON.parse(localStorage.getItem('user'));
            if (userData.token) {
                axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
            } else {
                let token = document.cookie.match(new RegExp("ei-token=([^;]*)"));
                axios.defaults.headers.common = {
                    Authorization: `Bearer ${token[1].replace("%7C", "|")}`,
                };
            }

            let orderingProducts = JSON.parse(localStorage.getItem('product')).map(
                (product) => {
                    return {id: product.id, product_count: product.product_count}
                })
            let utm = document.cookie.match(new RegExp("utm_query=([^;]*)"))
            if (utm) data.utm = utm[1]
            return await axios
                .post(process.env.VUE_APP_API_URL + '/orders/create', {...data, products: orderingProducts})
                .then(res => {
                    return res.data
                })
        },
        // eslint-disable-next-line no-unused-vars
        async couponCheck({commit}, coupon) {
            let orderingProducts = JSON.parse(localStorage.getItem('product')).map(
                (product) => {
                    return {id: product.id, product_count: product.product_count}
                })
            return await axios
                .post(process.env.VUE_APP_API_URL + '/coupons/calculate', {coupon: coupon, products: orderingProducts})
                .then(res => {
                    return res.data
                })
        },

        async loginByEmail({commit}, data) {
            return await axios
                .post(process.env.VUE_APP_AUTH_URL + '/sso', data)
                .then((res) => {
                    if (res?.data.token) {
                        commit('setUserData', res.data)
                        return res.data;
                    }
                })
        },
        async getCurrentUser({commit}) {
            await axios.get(process.env.VUE_APP_AUTH_URL + `/user`)
                .then(({data}) => {
                    commit("setUserData", data)
                })
        },
        async getCurrentProfile({commit}) {
            let userData = JSON.parse(localStorage.getItem('user'));
            axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
            await axios.get(process.env.VUE_APP_LK_BACK + `/api/profile`)
                .then(({data}) => {
                    commit("setUserProfileData", data)
                })
        },
        async logout({commit}) {
            if (localStorage.getItem('user')) {
                let userData = JSON.parse(localStorage.getItem('user'))
                axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
                await axios
                    .get(process.env.VUE_APP_AUTH_URL + '/logout')
                    .then(() => {
                        commit('clearUserData')
                    })
                    .catch(err => {
                        console.log('logout', err)
                    });
            }
        },
    }
}